var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"sort-by":"brewery.name","sort-desc":"","items-per-page":50,"footer-props":{
      itemsPerPageOptions: [20, 30, 50, -1],
    }},scopedSlots:_vm._u([{key:"item.isOnline",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item.isOnline ? 'green' : 'red darken-4',"outlined":""}},[_vm._v(" "+_vm._s(item.isOnline ? "ONLINE" : "OFFLINE")+" "),(!item.isOnline)?_c('v-icon',{staticClass:"ml-2",attrs:{"x-small":""}},[_vm._v("mdi-wifi-off")]):_vm._e(),(item.isOnline)?_c('v-icon',{staticClass:"ml-2",attrs:{"x-small":""}},[_vm._v("mdi-wifi")]):_vm._e()],1)]}},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"plain":"","to":'/device/' + item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.location.place",fn:function(ref){
    var item = ref.item;
return [_c('div',[(item.location.place == 'in-house')?_c('v-chip',{attrs:{"small":"","color":"grey","outlined":""}},[_vm._v(" "+_vm._s(item.location.place)+" ")]):_c('v-chip',{attrs:{"small":"","color":"green","outlined":""}},[_vm._v(" "+_vm._s(item.location.place)+" ")])],1)]}},{key:"item.brewery.name",fn:function(ref){
    var item = ref.item;
return [(item.brewery)?_c('v-btn',{attrs:{"plain":"","to":'/brewery/' + item.brewery.id}},[_vm._v(" "+_vm._s(item.brewery.name)+" ")]):_vm._e()]}}],null,true)}),_c('v-card-actions',{attrs:{"center":""}},[_c('v-btn',{attrs:{"color":"primary","outlined":"","disabled":"","block":""}},[_vm._v("Add Device")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }