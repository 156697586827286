





































import { Component, Vue } from "vue-property-decorator";
import Login from "@/components/okta-login.vue";
import Breweries from "@/components/add-brewery.vue";
import DeviceList from "@/components/devices-list.vue";

@Component({
  components: {
    Login,
    Breweries,
    DeviceList,
  },
  computed: {},
  data: () => ({
    selected: null,
  }),
  methods: {},
})
export default class Home extends Vue {}
