



































































import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
  props: ["items"],
  computed: {},
  data: () => ({
    search: "",
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
      },
      { text: "Brewery", value: "brewery.name" },
      { text: "Location", value: "location.place" },
      { text: "Batches", value: "stats.batchCountTotal" },
      // { text: "Hours in Operation", value: "stats.batchDurationTotal" },
      { text: "State", value: "isOnline" },
      { text: "Serial", value: "serialNumber" },
    ],
  }),
  methods: {
    getColor(status) {
      if (status == "MONITORING") return "blue";
      if (status == "ONLINE") return "green";
      else if (status == "OFFLINE") return "red darken-4";
      else return "red";
    },
  },
})
export default class Breweries extends Vue {}
